import React, { useMemo, useState } from "react";
import { Button } from "@commonsku/styles";
import { useHasCapabilities } from "../../hooks";
import CreateBillPopup, { FormType } from "../bills/CreateBillPopup";


function CreateBillButton() {
  const hasCreateBill = useHasCapabilities(["CREATE-BILL"]);
  const [showCreateBill, setShowCreateBill] = useState(false);

  if (!hasCreateBill) {
    return null;
  }

  return (
    <>
      {showCreateBill && (
        <CreateBillPopup
          onClose={() => setShowCreateBill(false)}
        />
      )}
      <Button cta onClick={() => setShowCreateBill(true)}>
        Create Bill
      </Button>
    </>
  );
};

export default CreateBillButton;
