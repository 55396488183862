import React, { useCallback } from "react";
import { Button } from "@commonsku/styles";
import { useReportContext } from "../report/report_context";
import { useHasCapabilities } from "../../hooks";
import { dataToUrlParams, downloadCsv } from "../../utils";

const DEFAULT_EXPORT_URL = "export_report.php";

const ExportReportButton = () => {
  const {
    reportConfig: { type, endpoint },
    getRawQuery,
  } = useReportContext();
  const hasExportReport = useHasCapabilities("EXPORT-REPORT");

  const exportReport = useCallback(
    async () => {
      const query = getRawQuery();
      await downloadCsv(`${endpoint}?${dataToUrlParams(query)}`, type.toLowerCase() + ".csv");
    },
    [endpoint, getRawQuery, type],
  );

  if (!hasExportReport) {
    return null;
  }

  return (
    <Button secondary onClick={exportReport}>
      Download Report
    </Button>
  );
};

export default ExportReportButton;
