import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { AlertNotification, Button, colors, Input, LabeledRadio, Popup, Typography } from "@commonsku/styles";
import { PopupHeaderWithCloseIcon } from "../report/action-menu/LoadReportPopup";

import { Bill } from "../../types/bills";
import { getDefaultBillFromPONumber, getDefaultBillFromSONumber} from "../../redux/bills";
import BillPopup from "./BillPopup";
import { oauth } from "../../utils";

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0;
  overflow-y: hidden;
`;

const PopupActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.5rem;
`;

const Instructions = styled(Typography.Text)`
&&& {
  background-color: ${colors.navy.lightest};
  border-radius: 5px;
  padding: 1rem;
}
`;

const CREATE_BILL_POPUP_STYLE = {
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
};

const CREATE_BILL_POPUP_CONTENT_STYLE = {
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "space-between",
  flexGrow: 1,
  overflowY: "hidden",
} as React.CSSProperties;

export type FormType = "PURCHASE-ORDER" | "SALES-ORDER";

interface CreateBillPopupProps {
  onClose: () => void;
}

export default function CreateBillPopup(
  {
    onClose,
  } : CreateBillPopupProps
) {

  const [source, setSource] = useState<FormType>("PURCHASE-ORDER");
  const [poNumber, setPONumber] = useState("");
  const [soNumber, setSONumber] = useState("");
  const [errorField, setErrorField] = useState<FormType | undefined>();
  const [error, setError] = useState("");
  const [bill, setBill] = useState<Bill | undefined>();

  const isValid = !errorField && (
    source === "PURCHASE-ORDER" ?
      /^[0-9-]+$/.test(poNumber) :
      /^[0-9]+$/.test(soNumber)
  );

  const handleChangePONumber = useCallback((e) => {
    setPONumber(e.target.value);
    setErrorField(undefined);
    setError("");
  }, [errorField]);

  const handleChangeSONumber = useCallback((e) => {
    setSONumber(e.target.value);
    setErrorField(undefined);
    setError("");
  }, [errorField]);

  const handleContinue = async () => {
    try {
      const bill = (source === "SALES-ORDER") ?
        (await getDefaultBillFromSONumber(soNumber)) :
        (await getDefaultBillFromPONumber(poNumber));
      setBill(bill);
    } catch (e) {
      setError(e);
      setErrorField(source);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && isValid) {
      handleContinue();
    }
  };

  return (
    <Popup
      closeOnEsc
      closeOnClickOutside={false}
      onClose={onClose}
      width="576px"
      height="411px"
      style={CREATE_BILL_POPUP_STYLE}
      popupContentStyle={CREATE_BILL_POPUP_CONTENT_STYLE}
      header={
        <PopupHeaderWithCloseIcon title="Create Bill" onPopupClose={onClose} />
      }
    >
      {bill && <BillPopup bill={bill} onClose={onClose} />}
      <PopupContent>
        {error ? (
          <AlertNotification alertType="error" style={{ marginBottom: "1rem" }}>
            {error}
          </AlertNotification>
        ) : (
          <Instructions>
            Create a bill by entering either a purchase order or a sales order.  You can create a bill for multiple POs by selecting them on the Receive Bills report.
          </Instructions>
        )}
        <LabeledRadio
          label="Purchase Order #"
          name="bill-source"
          checked={source === "PURCHASE-ORDER"}
          onChange={() => setSource("PURCHASE-ORDER")}
          labelStyle={{ margin: 0 }}
        />
        <Input
          value={poNumber}
          onFocus={() => setSource("PURCHASE-ORDER")}
          onChange={handleChangePONumber}
          onKeyDown={handleKeyDown}
          style={{ marginLeft: "2rem", width: "auto" }}
          error={errorField === "PURCHASE-ORDER"}
        />
        <LabeledRadio
          label="Sales Order #"
          name="bill-source"
          checked={source === "SALES-ORDER"}
          onChange={() => setSource("SALES-ORDER")}
          labelStyle={{ margin: 0 }}
        />
        <Input
          value={soNumber}
          onFocus={() => setSource("SALES-ORDER")}
          onChange={handleChangeSONumber}
          onKeyDown={handleKeyDown}
          style={{ marginLeft: "2rem", width: "auto" }}
          error={errorField === "SALES-ORDER"}
        />
      </PopupContent>
      <PopupActions>
        <Button secondary onClick={onClose}>Cancel</Button>
        <Button disabled={!isValid} onClick={handleContinue}>Continue</Button>
      </PopupActions>
    </Popup>
  );
}
